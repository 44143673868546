import React, { useState } from "react";
import { Container, Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CartItem from "./CartItem/CartItem";
import useStyles from "./styles";

const Cart = ({ cart, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
  const [showIframe, setShowIframe] = useState(false);
  const classes = useStyles();

  const handleEmptyCart = () => onEmptyCart();
  
  const handleShowIframe = () => {
    setShowIframe(true);
  };

  const renderEmptyCart = () => (
    <Typography variant="subtitle1">
      You have no items in your shopping cart,
      <Link className={classes.link} to="/">
        {" "}
        start adding some
      </Link>
      !
    </Typography>
  );

  if (!cart.line_items) return "Loading";

  return (
    <>
      <Container>
        <div className={classes.toolbar} />
        <Typography className={classes.title} variant="h5" gutterBottom>
          <b>Your Shopping Cart</b>
        </Typography>
        <hr />
        {showIframe ? (
          <iframe
            src={`https://docs.google.com/forms/d/e/1FAIpQLSd3QVZSpd12Nzo9ATO2Pp6SDxQKBXXXTOWS-tpAJCWuQOIM5g/viewform?embedded=true&entry.1216294383=${encodeURIComponent(
              cart.hosted_checkout_url
            )}`}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 9999,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        ) : (
          <>
            <Grid container spacing={4}>
              {cart.line_items.map((lineItem) => (
                <Grid item xs={12} sm={4} key={lineItem.id}>
                  <CartItem
                    item={lineItem}
                    onUpdateCartQty={onUpdateCartQty}
                    onRemoveFromCart={onRemoveFromCart}
                  />
                </Grid>
              ))}
            </Grid>
            <div className={classes.cardDetails}>
              <Typography variant="h5">
                Subtotal: <b>{cart.subtotal.formatted_with_symbol}</b>
              </Typography>
              <div>
                <Button
                  className={classes.emptyButton}
                  size="large"
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleEmptyCart}
                >
                  Empty cart
                </Button>
                <Button
                  className={classes.checkoutButton}
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleShowIframe}
                >
                  Process To Processing
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Cart;
